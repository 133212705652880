// PhotographersList.js

import React, { useState, useEffect } from "react";
import axiosInstance from "../../axiosConfig";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import "./PhotographersList.css";

function PhotographersList() {
	const [photographers, setPhotographers] = useState([]);

	useEffect(() => {
		// Получаем список фотографов с сервера
		axiosInstance
			.get("/photographers")
			.then((response) => setPhotographers(response.data.photographers))
			.catch((error) =>
				console.error("Ошибка при получении фотографов:", error)
			);
	}, []);

	const handleStatusChange = (photographerId, newStatus) => {
		axiosInstance
			.put(`/photographers/${photographerId}/status`, {
				status: newStatus,
			})
			.then((response) => {
				alert("Статус фотографа успешно обновлен");
				// Обновляем список фотографов
				setPhotographers((prevPhotographers) =>
					prevPhotographers.map((photographer) =>
						photographer._id === photographerId
							? { ...photographer, status: newStatus }
							: photographer
					)
				);
			})
			.catch((error) => {
				console.error(
					"Ошибка при обновлении статуса фотографа:",
					error
				);
				alert("Не удалось обновить статус фотографа");
			});
	};

	return (
		<div className="admin-photographers-list">
			<Sidebar />
			<div className="photographers-content">
				<Navbar />
				<h2>Управление фотографами</h2>
				<table>
					<thead>
						<tr>
							<th>Имя</th>
							<th>Статус</th>
							<th>Опыт</th>
							<th>Действия</th>
						</tr>
					</thead>
					<tbody>
						{photographers.map((photographer) => (
							<tr key={photographer._id}>
								<td>
									{photographer.firstName}{" "}
									{photographer.lastName}
								</td>
								<td>{photographer.status}</td>
								<td>{photographer.experience} лет</td>
								<td>
									<select
										value={photographer.status}
										onChange={(e) =>
											handleStatusChange(
												photographer._id,
												e.target.value
											)
										}
									>
										<option value="novice">Новичок</option>
										<option value="intermediate">
											Профессионал
										</option>
										<option value="pro">Эксперт</option>
									</select>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default PhotographersList;
