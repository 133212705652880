import React from "react";
import { useNavigate } from "react-router-dom";
import "./Navbar.css"; // Добавим стили

function Navbar() {
	const navigate = useNavigate();

	const handleProfileClick = () => {
		navigate("/profile");
	};

	const handleHomeClick = () => {
		navigate("/");
	};

	return (
		<nav className="navbar">
			<div className="navbar-logo" onClick={handleHomeClick}>
				{/* Логотип или название приложения */}
				<h2>PhotoFinder</h2>
			</div>
			<div className="navbar-menu">
				<button onClick={handleProfileClick}>
					{/* Иконка профиля */}
					Мой профиль
				</button>
			</div>
		</nav>
	);
}

export default Navbar;
