import React, { useEffect, useState } from "react";
import ClientRoutes from "../client/ClientRoutes";

const TelegramUserInfo = () => {
	const [userData, setUserData] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (window.Telegram && window.Telegram.WebApp) {
			// Инициализация Web App
			window.Telegram.WebApp.ready();

			// Проверка наличия initDataUnsafe
			const user = window.Telegram.WebApp.initDataUnsafe?.user;
			if (user) {
				setUserData(user);
			} else {
				// setError("Не удалось получить данные пользователя");
			}
		} else {
			setError("Telegram Web App не инициализирован");
		}
		console.log(userData);
	}, []);

	return (
		<div className="login-container">
			{error && <p className="error">{error}</p>}
			{userData ? (
				<ClientRoutes userData={userData} />
			) : (
				<p>Загрузка данных пользователя...</p>
			)}
		</div>
	);
};

export default TelegramUserInfo;
