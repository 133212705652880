// BookingsManager.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import "./BookingsManager.css";
import axiosInstance from "../../axiosConfig";

function BookingsManager() {
	const [bookings, setBookings] = useState([]);

	useEffect(() => {
		// Fetch bookings from the server
		axiosInstance
			.get("/bookings")
			.then((response) => setBookings(response.data))
			.catch((error) => console.error("Error fetching bookings:", error));
	}, []);

	return (
		<div className="admin-bookings-manager">
			<Sidebar />
			<div className="bookings-content">
				<Navbar />
				<h2>Manage Bookings</h2>
				<table>
					<thead>
						<tr>
							<th>Client</th>
							<th>Photographer</th>
							<th>Date</th>
							<th>Time Slot</th>
							<th>Status</th>
							{/* Add more columns as needed */}
						</tr>
					</thead>
					<tbody>
						{bookings.map((booking) => (
							<tr key={booking._id}>
								<td>{booking.clientId}</td>
								<td>{booking.photographerId}</td>
								<td>
									{new Date(
										booking.date
									).toLocaleDateString()}
								</td>
								<td>{booking.timeSlot}</td>
								<td>{booking.status}</td>
								{/* Add more data as needed */}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default BookingsManager;
