import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "./BookingPage.css";
import axiosInstance from "../../axiosConfig";
import Navbar from "../components/Navbar";
import Container from "../components/Container/Container";

function BookingPage() {
	const { id } = useParams(); // ID фотографа

	const [availableSlots, setAvailableSlots] = useState([]);
	const [selectedDate, setSelectedDate] = useState("");
	const [selectedTime, setSelectedTime] = useState("");
	const [hasPastDates, setHasPastDates] = useState(false); // Состояние для проверки прошлых дат
	const location = useLocation();
	const [photographer, setPhotographer] = useState(
		location.state?.photographer
	);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchPhotographer = async () => {
			try {
				const response = await axiosInstance.get(
					`/photographers/${id}`
				);
				setPhotographer(response.data);
			} catch (error) {
				console.error("Ошибка при получении данных фотографа:", error);
			}
		};
		const checkAndDeletePastDates = async () => {
			try {
				// Получаем текущую дату без учета времени
				const currentDate = new Date();
				currentDate.setHours(0, 0, 0, 0);

				// Получаем расписание фотографа
				const response = await axiosInstance.get(
					`/photographers/${id}/schedule`
				);

				// Проверяем, есть ли даты меньше текущей
				const hasPastDates = response.data.schedule.some((slot) => {
					const slotDate = new Date(slot.date); // Преобразуем строку в дату
					slotDate.setHours(0, 0, 0, 0); // Убираем время
					return slotDate < currentDate; // Сравниваем только дату
				});

				if (hasPastDates) {
					setHasPastDates(true);
				} else {
					setHasPastDates(false);
				}
			} catch (error) {
				console.error(
					"Ошибка при проверке и удалении прошедших дат:",
					error
				);
			}
		};

		fetchPhotographer();
		checkAndDeletePastDates();
	}, [id]);

	const handleDateChange = async (e) => {
		setSelectedDate(e.target.value);
		try {
			const response = await axiosInstance.get(
				`/photographers/${id}/schedule`,
				{
					params: { date: e.target.value },
				}
			);

			// Получаем выбранную дату без учета времени
			const selectedDate = new Date(e.target.value);
			selectedDate.setHours(0, 0, 0, 0);

			// Получаем текущую дату без учета времени
			const currentDate = new Date();
			currentDate.setHours(0, 0, 0, 0);

			// Проверяем, является ли дата прошедшей
			const isPastDate = selectedDate < currentDate;
			if (isPastDate) {
				setHasPastDates(true);
			} else {
				setHasPastDates(false);
			}

			setAvailableSlots(response.data.availableSlots);
		} catch (error) {
			console.error("Ошибка при получении слотов:", error);
		}
	};

	const handleBooking = async () => {
		try {
			const initDataUnsafe = window.Telegram?.WebApp?.initDataUnsafe;
			const clientTelegramId = initDataUnsafe?.user?.id;

			// Получаем данные клиента по telegramId
			const clientResponse = await axiosInstance.get(
				`/clients/telegram/${clientTelegramId}`
			);
			const clientId = clientResponse.data._id;

			// Создаем бронирование
			const bookingData = {
				clientId,
				photographerId: photographer._id,
				date: selectedDate,
				timeSlot: selectedTime,
			};
			await axiosInstance.post("/bookings", bookingData);

			alert(
				"Бронирование успешно создано! Перейдите в раздел 'Мои бронирования', чтобы подтвердить бронирование!"
			);
			navigate("/");
		} catch (error) {
			console.error("Ошибка при создании бронирования:", error);
			alert("Произошла ошибка при создании бронирования.");
		}
	};

	// Если фотографа нет, показываем сообщение о загрузке
	if (!photographer) return <div>Загрузка...</div>;

	return (
		<div className="booking">
			<Navbar />
			<Container>
				<div className="bookingInner">
					<div className="imageBox">
						<img src="/images/photo-card.jpeg" />
					</div>
					<div className="contentBox">
						<div className="adInfoBlock">
							<h2 className="title">
								{photographer.firstName} {photographer.lastName}
							</h2>
							<div className="adInfoInner">
								<div className="adInfoItem">
									Возраст: {photographer.age}
								</div>
								<div className="delimiter"></div>
								<div className="adInfoItem">
									Силь: {photographer.favoriteStyles[0]}
								</div>
							</div>
						</div>
						<div className="adInfoBlock">
							<h2 className="title">Опыт работы:</h2>
							<p className="adInfoText">
								Я занимаюсь фотографией уже более 4 лет и за это
								время прошёл путь от новичка до профессионала. Я
								начинал с портретной и уличной съёмки, но со
								временем открыл для себя множество других
								жанров: свадебную, репортажную, рекламную
								фотографию. Мой опыт включает работу как с
								частными клиентами, так и с корпоративными
								заказами.
							</p>
						</div>
					</div>
				</div>
				<div className="selectionBlock">
					<label>
						Выберите дату:
						<input
							type="date"
							value={selectedDate}
							onChange={handleDateChange}
						/>
					</label>
					{hasPastDates ? (
						<p>
							Вы выбрали прошедшую дату. Пожалуйста, выберите
							другую.
						</p>
					) : availableSlots.length > 0 ? (
						<>
							<label>
								Выберите время:
								<select
									value={selectedTime}
									onChange={(e) =>
										setSelectedTime(e.target.value)
									}
								>
									<option value="">
										-- Выберите время --
									</option>
									{availableSlots.map((slot, index) => (
										<option key={index} value={slot}>
											{slot}
										</option>
									))}
								</select>
							</label>
							<button onClick={handleBooking}>
								Подтвердить бронирование
							</button>
						</>
					) : (
						selectedDate && (
							<p>
								Нет доступных временных слотов на выбранную
								дату.
							</p>
						)
					)}
				</div>
			</Container>
		</div>
	);
}

export default BookingPage;
