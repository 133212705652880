// ClientRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import SearchPage from "./pages/SearchPage";
import PhotographerDetails from "./pages/PhotographerDetails";
import BookingPage from "./pages/BookingPage";
import ProfilePage from "./pages/ProfilePage";

function ClientRoutes({ userData }) {
	return (
		<Routes>
			<Route path="/" element={<SearchPage userData={userData} />} />
			<Route path="/photographer/:id" element={<PhotographerDetails />} />
			<Route path="/photographer/:id/book" element={<BookingPage />} />
			<Route path="/profile" element={<ProfilePage />} />
			{/* Добавьте другие клиентские маршруты, если необходимо */}
		</Routes>
	);
}

export default ClientRoutes;
