// AdminAuthContext.js

import React, { createContext, useState, useContext } from "react";

const AdminAuthContext = createContext();

export function useAdminAuth() {
	return useContext(AdminAuthContext);
}

export function AdminAuthProvider({ children }) {
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	const login = (credentials) => {
		// Implement your authentication logic here
		// For example, send credentials to the server and verify
		if (
			credentials.username === "admin" &&
			credentials.password === "admin123"
		) {
			setIsAuthenticated(true);
		} else {
			alert("Invalid credentials");
		}
	};

	const logout = () => {
		setIsAuthenticated(false);
	};

	return (
		<AdminAuthContext.Provider value={{ isAuthenticated, login, logout }}>
			{children}
		</AdminAuthContext.Provider>
	);
}
