// Dashboard.js

import React from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import "./Dashboard.css";

function Dashboard() {
	return (
		<div className="admin-dashboard">
			<Sidebar />
			<div className="dashboard-content">
				<h2>Welcome to the Admin Dashboard</h2>
				{/* You can add dashboard widgets and stats here */}
			</div>
		</div>
	);
}

export default Dashboard;
