import { createContext, useState, useEffect } from "react";
import axiosInstance from "../axiosConfig";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [token, setToken] = useState(null);
	const [user, setUser] = useState(null);

	useEffect(() => {
		const storedToken = localStorage.getItem("token");
		if (storedToken) {
			setToken(storedToken);
			axiosInstance
				.get("/users/me", {
					headers: { Authorization: `Bearer ${storedToken}` },
				})
				.then((response) => setUser(response.data))
				.catch(() => setToken(null));
		}
	}, []);

	const login = async (username, password) => {
		const response = await axiosInstance.post("/login", {
			username,
			password,
		});
		const newToken = response.data.token;
		setToken(newToken);
		localStorage.setItem("token", newToken);
		const userResponse = await axiosInstance.get("/users/me", {
			headers: { Authorization: `Bearer ${newToken}` },
		});
		setUser(userResponse.data);
	};

	const logout = () => {
		setToken(null);
		setUser(null);
		localStorage.removeItem("token");
	};

	return (
		<AuthContext.Provider value={{ token, user, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};
