// ProfilePage.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./ProfilePage.css";
import axiosInstance from "../../axiosConfig";
import Navbar from "../components/Navbar";
import Container from "../components/Container/Container";

function ProfilePage() {
	const [clientData, setClientData] = useState(null);
	const [bookings, setBookings] = useState([]);

	useEffect(() => {
		const fetchClientData = async () => {
			const initDataUnsafe = window.Telegram?.WebApp?.initDataUnsafe;
			const clientTelegramId = initDataUnsafe?.user?.id;

			try {
				const clientResponse = await axiosInstance.get(
					`/clients/telegram/${clientTelegramId}`
				);
				setClientData(clientResponse.data);

				const bookingsResponse = await axios.axiosInstance(
					`/bookings/client/${clientResponse.data._id}`
				);
				setBookings(bookingsResponse.data);
			} catch (error) {
				console.error("Ошибка при получении данных клиента:", error);
			}
		};

		fetchClientData();
	}, []);

	if (!clientData) return <div>Загрузка...</div>;

	return (
		<div className="profile">
			<Navbar />
			<Container>
				<div className="profile-page">
					<h1>Мой профиль</h1>
					<p>Имя: {clientData.name}</p>
					<p>Телефон: {clientData.phone}</p>
					{/* Добавьте другие поля, если необходимо */}
					<h2>Мои бронирования</h2>
					{bookings.length > 0 ? (
						<ul>
							{bookings.map((booking) => (
								<li key={booking._id}>
									Фотограф: {booking.photographerId} <br />
									Дата:{" "}
									{new Date(
										booking.date
									).toLocaleDateString()}{" "}
									<br />
									Время: {booking.timeSlot} <br />
									Статус: {booking.status}
								</li>
							))}
						</ul>
					) : (
						<p>У вас нет бронирований.</p>
					)}
				</div>
			</Container>
		</div>
	);
}

export default ProfilePage;
