import React, { useState, useEffect } from "react";
import axios from "axios";
import PhotographerCard from "../components/PhotographerCard";
import Navbar from "../components/Navbar";
import "./SearchPage.css";
import axiosInstance from "./../../axiosConfig";
import Container from "../components/Container/Container";

function SearchPage({ userData }) {
	const [photographers, setPhotographers] = useState([]);
	const [search, setSearch] = useState("");
	const [level, setLevel] = useState("");
	const [age, setAge] = useState("");
	const [styles, setStyles] = useState("");
	const [experience, setExperience] = useState("");
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		fetchPhotographers();
	}, [search, level, age, styles, experience, page]);

	const fetchPhotographers = async () => {
		setLoading(true);
		try {
			const response = await axiosInstance.get("/photographers", {
				params: {
					search,
					level,
					age,
					styles,
					experience,
					clientTelegramId: userData?.id,
					page,
					limit: 10,
				},
			});
			setPhotographers(response.data.photographers);
			setTotalPages(response.data.pages);
		} catch (error) {
			console.error("Ошибка при получении фотографов:", error);
		} finally {
			setLoading(false);
		}
	};

	// Обработчики изменения фильтров и пагинации
	// ...

	return (
		<div className="search-page">
			<Navbar />
			<Container>
				<h1>Поиск фотографов</h1>
				{/* Форма поиска и фильтров */}
				<div className="search-filters">
					<div className="inputBox">
						<input
							type="text"
							placeholder="Поиск по имени"
							value={search}
							onChange={(e) => {
								setSearch(e.target.value);
								setPage(1);
							}}
						/>
					</div>
					<div className="inputBox">
						<input
							type="number"
							placeholder="Мин. возраст"
							value={age}
							onChange={(e) => {
								setAge(e.target.value);
								setPage(1);
							}}
						/>
					</div>
					<div className="inputBox">
						<input
							type="text"
							placeholder="Стили (через запятую)"
							value={styles}
							onChange={(e) => {
								setStyles(e.target.value);
								setPage(1);
							}}
						/>
					</div>
					<div className="inputBox">
						<input
							type="number"
							placeholder="Опыт (лет)"
							value={experience}
							onChange={(e) => {
								setExperience(e.target.value);
								setPage(1);
							}}
						/>
					</div>
					<div className="selectBox">
						<select
							value={level}
							onChange={(e) => {
								setLevel(e.target.value);
								setPage(1);
							}}
						>
							<option value="">Все уровни</option>
							<option value="novice">Новичок</option>
							<option value="intermediate">Профессионал</option>
							<option value="pro">Эксперт</option>
						</select>
					</div>
				</div>
			</Container>
			{loading ? (
				<p>Загрузка...</p>
			) : (
				<Container>
					<>
						{photographers.length > 0 ? (
							<div className="photographers-list">
								{photographers.map((photographer) => (
									<PhotographerCard
										key={photographer._id}
										photographer={photographer}
									/>
								))}
							</div>
						) : (
							<p>Фотографы не найдены</p>
						)}
						<div className="pagination">
							<button
								onClick={() => setPage(page - 1)}
								disabled={page === 1}
							>
								Предыдущая
							</button>
							<span>
								Страница {page} из {totalPages}
							</span>
							<button
								onClick={() => setPage(page + 1)}
								disabled={page === totalPages}
							>
								Следующая
							</button>
						</div>
					</>
				</Container>
			)}
		</div>
	);
}

export default SearchPage;
