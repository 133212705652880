import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "./PhotographerDetails.css";
import axiosInstance from "../../axiosConfig";

function PhotographerDetails() {
	const { id } = useParams();
	const [photographer, setPhotographer] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchPhotographer = async () => {
			try {
				const response = await axiosInstance.get(`/photographers/${id}`);
				setPhotographer(response.data);
			} catch (error) {
				console.error("Ошибка при получении данных фотографа:", error);
			}
		};

		fetchPhotographer();
	}, [id]);

	if (!photographer) return <div>Загрузка...</div>;

	const handleBookClick = () => {
		navigate(`/photographer/${id}/book`);
	};

	return (
		<div className="photographer-details">
			<h1>
				{photographer.firstName} {photographer.lastName}
			</h1>
			<img
				src={`http://localhost:3000/${photographer.profilePhoto}`}
				alt={photographer.firstName}
			/>
			<p>Возраст: {photographer.age}</p>
			<p>Опыт: {photographer.experience} лет</p>
			<p>Любимые стили: {photographer.favoriteStyles.join(", ")}</p>
			<button onClick={handleBookClick}>Забронировать</button>
		</div>
	);
}

export default PhotographerDetails;
