// App.js

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminRoutes from "./admin/AdminRoutes";
import { AdminAuthProvider } from "./admin/AdminAuthContext";
import TelegramUserInfo from "./components/TelegramUserInfo";

function App() {
	return (
		<Router>
			<AdminAuthProvider>
				<Routes>
					{/* Admin Routes */}
					<Route path="/admin/*" element={<AdminRoutes />} />

					{/* Client Routes */}
					<Route path="/*" element={<TelegramUserInfo />} />
				</Routes>
			</AdminAuthProvider>
		</Router>
	);
}

export default App;
