// src/client/components/Navbar.js

import React from "react";
import { useNavigate } from "react-router-dom";
import "./Navbar.css"; // Подключаем стили

function Navbar() {
	const navigate = useNavigate();

	const handleProfileClick = () => {
		navigate("/profile");
	};

	const handleHomeClick = () => {
		navigate("/");
	};

	return (
		<nav className="navbar">
			<div className="navbar-logo" onClick={handleHomeClick}>
				<img src={`/images/logo.png`} />
			</div>
			<div className="navbar-menu">
				<button onClick={handleProfileClick}>Мой профиль</button>
			</div>
			<img
				className="navbar_triangle"
				src={`/images/background-triangels.png`}
				alt="Динамическое изображение"
			/>
		</nav>
	);
}

export default Navbar;
