// AdminRoutes.js

import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminLogin from "./pages/AdminLogin";
import Dashboard from "./pages/Dashboard";
import UsersList from "./pages/UsersList";
import PhotographersList from "./pages/PhotographersList";
import BookingsManager from "./pages/BookingsManager";
import { useAdminAuth } from "./AdminAuthContext";

function RequireAuth({ children }) {
	const { isAuthenticated } = useAdminAuth();

	if (!isAuthenticated) {
		return <Navigate to="/admin/login" />;
	}

	return children;
}

function AdminRoutes() {
	return (
		<Routes>
			<Route path="/login" element={<AdminLogin />} />
			<Route
				path="/*"
				element={
					<RequireAuth>
						<Routes>
							<Route path="/" element={<Dashboard />} />
							<Route path="/users" element={<UsersList />} />
							<Route
								path="/photographers"
								element={<PhotographersList />}
							/>
							<Route
								path="/bookings"
								element={<BookingsManager />}
							/>
							{/* Add more admin routes as needed */}
						</Routes>
					</RequireAuth>
				}
			/>
		</Routes>
	);
}

export default AdminRoutes;
