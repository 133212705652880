// PhotographerCard.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "./PhotographerCard.css";

function PhotographerCard({ photographer }) {
	const navigate = useNavigate();

	const handleBookClick = () => {
		navigate(`/photographer/${photographer._id}/book`, {
			state: { photographer },
		});
	};

	return (
		<div className="photographer-card">
			<div className="photographer-image">
				<img
					src={`/${photographer.profilePhoto.substring(
						photographer.profilePhoto.indexOf("images")
					)}`}
					alt={`${photographer.firstName} ${photographer.lastName}`}
					className="photographer-photo"
				/>
			</div>
			<div className="photographer-info">
				<h2 className="title">
					{photographer.firstName} {photographer.lastName}
				</h2>
				<p>Возраст: {photographer.age}</p>
				<p>Опыт: {photographer.experience} лет</p>
				<p>Любимые стили: {photographer.favoriteStyles.join(", ")}</p>
				<button onClick={handleBookClick}>Забронировать</button>
			</div>
			<img
				className="backgroundTriangle"
				src={`/images/background-triangels.png`}
				alt="Динамическое изображение"
			/>
		</div>
	);
}

export default PhotographerCard;
